import React from 'react';
import video from '../assets/mainvideo2.MP4'

const VideoPlayer = () => {
  return (
    <div className="mx-auto my-8">
      <video controls className="w-10/12 mx-auto">
        <source src={video} type="video/mp4" />
        
      </video>
    </div>
  );
};

export default VideoPlayer;
