import React, { useState } from "react";
import Axios from "axios";

function Contact() {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const url = "https://ralla.in/insert.php";
  const [data, setData] = useState({
    name: "",
    mobile: "",
    message: ""
  });

  function submitForm(e) {
    e.preventDefault();

    // Check if all fields are filled out
    if (!data.name || !data.mobile || !data.message) {
      setErrorMessage("Please fill out all fields");
      return;
    }

    // Disable the submit button to prevent duplicate submissions
    setIsSubmitting(true);

    Axios.post(url, {
      name: data.name,
      mobile: data.mobile,
      message: data.message
    })
      .then(res => {
        console.log(res.data);
        setSuccessMessage("Message sent successfully!");
        setErrorMessage("");
        // Clear form fields after successful submission
        setData({ name: "", mobile: "", message: "" });
      })
      .catch(error => {
        console.error("Error:", error);
        setErrorMessage("An error occurred. Please try again later.");
        setSuccessMessage("");
      })
      .finally(() => {
        // Enable the submit button after the request is completed
        setIsSubmitting(false);
      });
  }

  function handleInputChange(e) {
    const { id, value } = e.target;
    setData({ ...data, [id]: value });
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl bg-slate-400 text-center font-bold mb-4 p-2">
        Contact Us
      </h1>
      <div className="flex justify-center items-center">
        <form
          className="max-w-md bg-slate-200 shadow-md rounded px-8 pt-6 pb-8 mb-4 w-96"
          onSubmit={submitForm}
        >
          {/* Form fields */}
          {/* Success and error messages */}
          {successMessage && (
            <div className="bg-green-200 text-green-800 p-2 rounded mb-4">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="bg-red-200 text-red-800 p-2 rounded mb-4">
              {errorMessage}
            </div>
          )}
          {/* Form fields */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Name
            </label>
            <input
              onChange={handleInputChange}
              id="name"
              value={data.name}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Your Name"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="mobile"
            >
              Mobile Number
            </label>
            <input
              onChange={handleInputChange}
              id="mobile"
              value={data.mobile}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="number"
              placeholder="Enter Mobile Number"
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="message"
            >
              Message
            </label>
            <textarea
              onChange={handleInputChange}
              id="message"
              value={data.message}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Your Message"
              rows="4"
            ></textarea>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
