import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faInstagram, faLinkedin,} from '@fortawesome/free-brands-svg-icons';
import MessageForm from './MessageForm';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <footer className="bg-slate-100 text-black pt-4 border-t mt-2">
      <div className="max-w-8xl mx-auto px-4 md:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="md:col-span-2">
            <h3 className="text-lg font-semibold mb-4">About Us</h3>
            <p className="text-base">
              Ralla Water purifiers are mainly helping the people to provide pure & safe drinking water with innovative products.
            </p>
            <div className="flex items-center mt-6 space-x-6">
              <a href="https://www.facebook.com/profile.php?id=61558139753237">
                <FontAwesomeIcon icon={faFacebook} className="text-blue-600 text-3xl hover:text-blue-800" />
              </a>
              <a href="https://www.instagram.com/ralla_waterpurifiers/?next=%2F">
                <FontAwesomeIcon icon={faInstagram} className="text-red-600 text-3xl hover:text-pink-800" />
              </a>
              <a href="https://x.com/RallaWater">
                <FontAwesomeIcon icon={faXTwitter} className="text-blue-500 text-3xl hover:text-blue-600" />
              </a>             
              <a href="https://www.linkedin.com/company/101745338/admin/page-posts/published/">
                <FontAwesomeIcon icon={faLinkedin} className="text-blue-600 text-3xl hover:text-green-800" />
              </a>
            </div>
          </div>
          <div className="md:col-span-1">
            <h3 className="text-lg font-semibold mb-4">Services</h3>
            <ul className="list-inside list-none">
              <li className="mb-2">Product Demo</li>
              <li className="mb-2">Installation</li>
              <li className="mb-2">Customer Service</li>
              {/* <li>Service 4</li> */}
            </ul>
          </div>
          <div className="md:col-span-1">
            <h3 className="text-lg font-semibold mb-4">Office Address</h3>
            <p>6-3-630/B, Anand Nagar Colony</p>
            <p>Oppo. Manoj Apartments</p>
            <p>Khairatabad, Hyderabad 500004</p>
            <div class="flex items-center space-x-2">
            <FontAwesomeIcon icon={faPhone} className="text-green-600 text-1xl hover:text-green-800" />
              <p class="text-lg font-normal">+91-6303010725</p>
            </div>
            <div class="flex items-center space-x-3">
            <FontAwesomeIcon icon={faEnvelope} className="text-red-600 text-1xl hover:text-green-800" />
              <p class="text-lg font-normal">support@ralla.in</p>
            </div>
          </div>
          <div className="md:col-span-4 mt-6 md:mt-0">
            <MessageForm />
          </div>
        </div>
      </div>
      <div className="mt-8">
        <div className="max-w-8xl mx-auto bg-black p-4 text-white">
          <p className="text-center">&copy; 2024 The CodeNext Solutions. All rights reserved.</p>
          <div className="flex justify-center mt-2">
            <a href="#" className="text-white hover:text-white mx-2">Privacy Policy</a>
            <a href="#" className="text-white hover:text-white mx-2">Terms of Service</a>
            <a href="#" className="text-white hover:text-white mx-2">Contact Us</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
