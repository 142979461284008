import React from "react";
import { useParams } from "react-router-dom";
import AdminPage from "./AdminPage";


function WelcomeAdmin() {
    const { username } = useParams();

    return (


        <div>
            <h1 className='bg-slate-300 px-auto text-start font-bold text-xl p-2 m-2'>Welcome Admin!</h1>
            {/* <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Welcome, {username}!</h2> */}
            <AdminPage/>
        </div>
        


    );
}

export default WelcomeAdmin;
