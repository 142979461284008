import React from 'react';
import wImage from '../assets/people_walking.jpg'
function Banner() {
  return (
    <div className="relative">
    <img src={wImage} alt="Banner" className="w-full h-96 object-cover" />
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-full">
      <h2 className="text-2xl md:text-2xl lg:text-4xl font-bold">
        <span className="rounded-md text-white px-4 py-2 md:bg-opacity-50">
          Innovation's that resolves water problems.
        </span>
      </h2>
      
      {/* <p className="text-lg md:text-xl text-blue mt-4">Discover amazing features and services</p> */}

     
    </div>
  </div>
  
  
  );
}

export default Banner;