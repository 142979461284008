import React from 'react';
import BackGroundImg from '../assets/backGroundBanner.png'; // Import the image
import AppStore from '../assets/AppStoreImage.png';
import Image1 from '../assets/image1.svg';
import Image2 from '../assets/image2.svg';
import Image3 from '../assets/image3.svg';


const AnnualMaintenancePage = () => {
    return (
        <div>
            {/* <h1 className=''>Annual Maintenance Page</h1> */}
            <div style={{ position: 'relative' }}>
                <img src={BackGroundImg} alt="Maintenance" class="w-full h-96 object-cover" />
                <div style={{ position: 'absolute', top: '20%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', width: '100%' }}>
                    <h2 style={{ fontSize: 55 }}>Maintain Your Peace with Ralla</h2>
                    <p style={{ fontSize: 25 }}>With Ralla you can avail an Annual Maintenance Contract (AMC) in 3 simple steps</p>
                </div>
                <div class="absolute top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex space-x-8">
                    <div class="flex-1 p-4">
                        <img src={Image1} alt="Image 1" class="w-30 h-auto" />
                        <p class="text-black ">1. Enter Serial Number</p>
                    </div>
                    <div class="flex-1 p-4">
                        <img src={Image2} alt="Image 1" class="w-30 h-auto" />
                        <p class="text-black">2. Validate</p>
                    </div>
                    <div class="flex-1 p-4">
                        <img src={Image3} alt="Image 1" class="w-30 h-auto" />
                        <p class="text-black whitespace-nowrap">3. Purchase AMC</p>
                    </div>
                </div>


                <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', textAlign: 'center', width: '80%' }}>
                    <p class="text-xl">Ralla Helpline Number</p>
                    <p>+91-6303010725</p>
                    <p>(7 Days a week, 9:00 am to 6:00pm)</p>
                    <p>or, send us an Email at</p>
                    <p>support@ralla.in</p>
                    <p>Download and use Ralla Service App for Android/IOS Phones, for service/repair, installation related issues</p>
                    <p>
                        -
                    </p>
                    <div class="flex justify-center" >
                        <img src={AppStore} alt="App Store" class="w-100 h-14" />
                    </div>

                </div>
            </div>

        </div>
    );
};

export default AnnualMaintenancePage;
