import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logoImage from './assets/Logo.png';
import RallaLogo from './assets/Ralla_Logo.png';

function Navigation() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="bg-indigo-950 p-1">
      <div className="max-w-7xl mx-auto px-2 flex items-center justify-between">
        {/* LogoImage */}
        <Link to="/" className="flex items-center">
          <img src={logoImage} alt="Logo" className="h-14" />
          {/* Display RallaLogo only on non-mobile views */}
          <div className="hidden md:block ml-2">
            <img src={RallaLogo} alt="Ralla Logo" className="h-10 mb-2" />
          </div>
        </Link>
        {/* FontAwesome icon for mobile view */}
        <div className="md:hidden">
          <FontAwesomeIcon icon={faBars} className="text-white text-2xl" onClick={toggleMobileMenu} />
        </div>
        {/* Web navigation items (hidden in mobile view) */}
        <div className="hidden md:flex md:items-center md:space-x-4">
          <Link to="/" className="text-gray-300 hover:bg-slate-100 hover:text-black px-3 py-2 rounded-md text-lg font-medium">Home</Link>          
          <Link to="/services" className="text-gray-300 hover:bg-slate-100 hover:text-black px-3 py-2 rounded-md text-lg font-medium">Customer Services</Link>
          <Link to="/products" className="text-gray-300 hover:bg-slate-100 hover:text-black px-3 py-2 rounded-md text-lg font-medium">Products</Link>
          <Link to="/contact" className="text-gray-300 hover:bg-slate-100 hover:text-black px-3 py-2 rounded-md text-lg font-medium">Contact Us</Link>
          <Link to="/about" className="text-gray-300 hover:bg-slate-100 hover:text-black px-3 py-2 rounded-md text-lg font-medium">About Us</Link>
          {/* <Link to="/adminlogin" className="text-gray-300 hover:bg-slate-100 hover:text-black px-3 py-2 rounded-md text-lg font-medium">Admin Login</Link> */}
        </div>
      </div>
      {/* Mobile menu items */}
      <div className={`md:hidden ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
        <div className="flex flex-col items-center">
          <Link to="/" className="text-gray-300 hover:bg-slate-100 hover:text-black px-3 py-2 rounded-md text-lg font-medium">Home</Link>
          <Link to="/about" className="text-gray-300 hover:bg-slate-100 hover:text-black px-3 py-2 rounded-md text-lg font-medium">About</Link>
          <Link to="/services" className="text-gray-300 hover:bg-slate-100 hover:text-black px-3 py-2 rounded-md text-lg font-medium">Customer Services</Link>
          <Link to="/products" className="text-gray-300 hover:bg-slate-100 hover:text-black px-3 py-2 rounded-md text-lg font-medium">Products</Link>
          <Link to="/contact" className="text-gray-300 hover:bg-slate-100 hover:text-black px-3 py-2 rounded-md text-lg font-medium">Contact</Link>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
