import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import WaterImage from '../assets/productImage.jpeg';
import carousals1 from '../assets/carousal1.JPG'
import carousals2 from '../assets/carousal21.jpg'

const Carousel = () => {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
     
    };
  
    return (
        <Slider {...settings}>
          <div>
          <img src={carousals2} alt="Image 2" className='h-72 mx-auto my-8'></img>
          </div>
          <div>
            <img src={carousals1} alt="Image 2" className='h-72 mx-auto my-8' />
          </div>
          <div>
          <img src={WaterImage} alt="Image 2" className='h-72 mx-auto my-8'></img>
          </div>
          {/* Add more images as needed */}
        </Slider>
    );
  };
  
  export default Carousel;
  