// About.js

import React from 'react';
import vinay from '../src/assets/vinay.jpeg'
import manoj from '../src/assets/manoj.jpeg'

function About() {
  const teamMembers = [
    {
      name: 'Manoj Reddy Ralla',
      role: 'Co-Founder',
      description: 'Manoj is an experienced Mechanical engineer with expertise in Water purifiers development.',
      imageUrl: manoj,
    },
    {
      name: 'Vinay Kumar B',
      role: 'Co-Founder',
      description: 'Vinay is a talented BCA (Bachelor of Computer Applications) with a passion for admistrating organizaion with 7 year Experience In IT System Administration/Network Engineer.',
      imageUrl: vinay,
    },
    // Add more team members as needed
  ];
  return (
    <div className=" container mx-auto p-4">
      <h1 className="text-3xl bg-slate-400 text-center font-bold mb-4 p-2">About Us</h1>
      <p className="text-lg text-gray-800 pb-4">
      <span className=''>Ralla Water purifiers are mainly helping the people to provide pure & safe drinking water with the innovative products.
      <br></br></span>
        We are on a Mission to change the lives of the people, build a healthy family and impact the nation by providing the pure and safe drinking water.
        Ralla was started to solve the water problems in the world with the innovative & sustainable product, We understand the critical importance of addressing water problems facing communities worldwide. Access to clean, safe, and reliable water is a fundamental human right, yet millions of people lack this basic necessity.
        Our mission is to tackle these challenges head-on by providing innovative solutions to purify and conserve water.<br></br> With a deep commitment to sustainability and environmental stewardship, we develop cutting-edge technologies that not only treat water but also promote its efficient use and conservation.
        Through our work, we aim to make a meaningful impact on water-stressed regions, improving health, enhancing ecosystems, and empowering communities. Join us in our mission to create a more sustainable and water-secure future for all.
        </p>
      <div className="container mx-auto text-center">
        <h1 className="text-3xl font-bold my-8 pb-6">Meet Our Team</h1>
        <div className="flex flex-wrap justify-center">
          {teamMembers.map((member, index) => (
            <div key={index} className="max-w-md bg-white shadow-md rounded-md p-8 m-4">
              <img src={member.imageUrl} alt={member.name} className="w-60 h-56  mx-auto rounded-full mb-6" />
              <h2 className="text-2xl text-blue-800 font-semibold mb-4">{member.name}</h2>
              <p className="text-lg text-gray-500 mb-4">{member.role}</p>
              <p className="text-base text-gray-700">{member.description}</p>
            </div>
          ))}
        </div>
      </div>



    </div>


  );
}

export default About;
