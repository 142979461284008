import './App.css';
import Home from './Main/Home';
import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import NotFound from './NotFound';
import Navigation from './navigation';
import ProductsPage from './Main/ProductsPage';
import AnnualMaintenancePage from './Main/AnnualMaintenancePage';
import AdminLogin from './Main/AdminLogin';
import WelcomeAdmin from "./Main/WelcomeAdmin";

function App() {
  return (

    <Router>
      <div>
        <Navigation />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/adminlogin" element={<AdminLogin></AdminLogin>}/>
          <Route path="/welcome-admin/:username" element={<WelcomeAdmin/>} />
          <Route path='/NotFound' element={<NotFound />} />
          <Route path='/annual-maintenance' element={<AnnualMaintenancePage/>}/>
          <Route path="/AdminLogin" component={AdminLogin}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
