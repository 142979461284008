
import React, { useState, useEffect } from 'react';
import Banner from './Banner'; // Import the Banner component
import Footer from '../Footer';
import waterPurifierImage from '../assets/waterpurifier.jpg';
import waterSetImage from '../assets/waterset.jpg';
import Carousel from './Carousel';
import VideoPlayer from './VideoPlayer';
import Popup from './Popup';

function Home() {


  return (
    <div className="home">
      <Popup />
      <Banner />     
      <h2 className='text-2xl font-bold bg-slate-400 p-2 m-2 text-center'>Our Product</h2>
      <div className='container mx-auto px-4 flex justify-center items-center h-full'>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          <VideoPlayer></VideoPlayer>
          <Carousel></Carousel>
        </div>
      </div>
      <div className="bg-gray-100 p-6 rounded-lg">
        <h2 className="text-xl font-semibold mb-2 text-violet-500 text-center">About Ralla Water Purifier</h2>
        <p className="text-gray-700">
        Ralla Water purifiers are devices that remove harmful impurities and contaminants from water to make it safe for drinking. 
        </p>
        <span>They come in various technologies, including: - </span><br/>
        <span>1. Only Reverse Osmosis (RO),  </span><br/>
        <span>2. Green Purifier – Ultraviolet (UV) + Ultra Filtration (UF) + Minerals (Ca, Mg, Copper), </span><br/>
        <span>3. Reverse Osmosis (RO) + Ultraviolet (UV)+ Minerals (Ca, Mg, Copper), </span><br/>
        <span>4. Reverse Osmosis (RO) + Ultra Filtration (UF)+ Minerals (Ca, Mg, Copper),</span><br/>
        <span>5. Reverse Osmosis (RO) + Ultraviolet (UV)+ Ultra Filtration (UF)+ Minerals (Ca, Mg, Copper) </span><br/>
        <span>along with the MTDS feature with respective to the customers input water source availability.</span><br/>
        <span>Benefits include improved taste, removal of harmful substances, and environmental friendliness. 
Consider water quality, usage, maintenance, and cost when choosing a purifier. 
Installation and regular maintenance are essential for optimal performance. 
</span><br/>
        <span>Overall, Water purifiers provides safe and clean drinking water, promoting better health and 
well-being. </span><br/>
        <span>Water quality: Consider the source of your water and the contaminants it may contain to choose the appropriate type of water purifier.</span>

      </div>

      <div className="bg-gray-100 h-32 flex justify-center items-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-red-800 mb-2">COMING SOON...!</h1>
          <p className="text-sm text-black font-semibold mb-4">We are working hard to bring you something amazing. Stay tuned!</p>
        </div>
      </div>


      <div className="container mx-auto text-center">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="max-w-md w-full mx-auto bg-white rounded-md overflow-hidden shadow-lg mb-4">
            <div className="bg-blue-500 py-6 mb-2 rounded-t-md">
              <h1 className="text-lg font-normal text-white">Water purifier which works without electricity</h1>
            </div>
            <img src={waterPurifierImage} alt="Water Purifier" className="w-full h-80 object-cover mb-2" />
            <div className="bg-gray-100 p-4">
              <h2 className="text-xl font-semibold mb-2">Description</h2>
              <p className="text-gray-700 text-start">
                <center>This purifier will able to work without electricity</center></p>
            </div>
          </div>
          <div className="max-w-md w-full mx-auto bg-white rounded-md overflow-hidden shadow-lg mb-4">
            <div className="bg-blue-500 py-6 mb-2 rounded-t-md">
              <h1 className="text-lg font-normal text-white">Hot & Cold water purifier with DIY technology.</h1>
            </div>
            <img src={waterSetImage} alt="Water Purifier" className="w-full h-80 object-cover mb-2" />
            <div className="bg-gray-100 p-4">
              <h2 className="text-xl font-semibold mb-2">Description</h2>
              <p className="text-gray-700">
                Hot & Cold water purifier which enables the customers to do self services without the need of technician.</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
