import React from 'react';
import { Link } from 'react-router-dom';

const Services = () => {
  return (
    <div className="services-page">
      {/* <h1>List Of Our Services</h1>  */}
      <div className="service">
        <h2>Water Purifier Installation</h2>
        <p>We offer professional installation of water purifiers in your home or office.</p>
      </div>
      <div className="service bg-white rounded-lg shadow-md p-6">
        <Link to="/annual-maintenance" className="block">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">Annual Maintenance Contract (AMC)</h2>
          <p className="text-gray-600">Ensure the optimal performance of your water purifier with our annual maintenance contract.</p>
        </Link>
      </div>
      <div className="service">
        <h2>Filter Replacement</h2>
        <p>Keep your water purifier working effectively by replacing filters regularly.</p>
      </div>
      <div className="service">
        <h2>Water Quality Testing</h2>
        <p>We provide water quality testing services to ensure the purity of your drinking water.</p>
      </div>
      <div className="service">
        <h2>Product Demonstrations</h2>
        <p>Get a demonstration of our water purifier products to understand their features and benefits.</p>
      </div>
    </div>
  );
};

export default Services;