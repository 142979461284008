import React, { useState, useEffect } from "react";
import Axios from "axios";

function AdminPage() {
  const [records, setRecords] = useState([]);

  useEffect(() => {
    // Fetch data from server when component mounts
    Axios.get("https://ralla.in/contactus.php")
      .then(response => {
        setRecords(response.data);
      })
      .catch(error => {
        console.error("Error fetching records:", error);
      });
  }, []); // Empty dependency array ensures effect runs only once on component mount

  return (
    <div class="container p-2">
    <h2 class="text-xl font-semibold mb-2">Records</h2>
    <div class="overflow-x-auto">
        <table class="min-w-full bg-white rounded-lg overflow-hidden shadow-md">
            <thead class="bg-slate-300 text-white">
                <tr>
                    <th class="px-4 py-2 text-left text-blue-700 border">Name</th>
                    <th class="px-4 py-2 text-left text-blue-700 border">Number</th>
                    <th class="px-4 py-2 text-left text-blue-700 border">Message</th>
                    <th class="px-4 py-2 text-left text-blue-700 border">Reached Time</th>
                    {/* <!-- Add more headers if needed --> */}
                </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
                {records.map((record, index) => (
                    <tr key={record.CUSTOMER_ID} class={index % 2 === 0 ? "bg-slate-100" : "bg-white"}>
                        <td class="px-4 py-2 whitespace-nowrap border">{record.CUSTOMER_NAME}</td>
                        <td class="px-4 py-2 whitespace-nowrap border">{record.PHONE_NUMBER}</td>
                        <td class="px-4 py-2 whitespace-nowrap border">{record.MESSAGE}</td>
                        <td class="px-4 py-2 whitespace-nowrap border">{record.CREATED_ON}</td>
                        {/* <!-- Add more data cells if needed --> */}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
</div>



  
  
    
  );
}

export default AdminPage;
