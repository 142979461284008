import React, { useState } from 'react';
import Modal from 'react-modal';
import Offer from '../assets/Offer.jpeg';
import { noAuto } from '@fortawesome/fontawesome-svg-core';
const Popup = () => {
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 3000,
        },
        content: {
          position: 'absolute',
          insert: '50% 40px 40px 50%',
          borderRadius: '4px',
          overflow: noAuto,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '350px',
          height: '500px',
          padding: '0px'
        },
      }}
    >
      <button onClick={closeModal} style={{ float: 'right' }}>
        X
      </button>
      <img src={Offer} alt="Popup Image" style={{ width: '100%', height: 'auto' }} />
    </Modal>
  );
};

export default Popup;
