import React, { useState } from 'react';

function MessageForm() {
  const [message, setMessage] = useState('');

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const sendMessage = () => {
    // Add your logic to send the message here
    console.log('Message sent:', message);
    // Clear the message input field
    setMessage('');
  };

  return (
    <div className="max-w-lg mx-auto">
      <h2 className="text-lg font-semibold mb-4">Write a Message to Us</h2>
      <textarea
        className="w-full border border-gray-300 rounded-md p-4 mb-4 h-24"
        placeholder="Write your message here..."
        value={message}
        onChange={handleMessageChange}
      ></textarea>
      <button
        className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
        onClick={sendMessage}
      >
        Send
      </button>
    </div>
  );
}

export default MessageForm;
