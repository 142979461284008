
import product1Image from '../assets/Product1.jpeg';
import product2Image from '../assets/Product2.jpeg';
import product3Image from '../assets/Product3.jpeg';
import product4Image from '../assets/Product4.jpeg';

const Products = [
    {
      id: 1,
      //name: 'Product 1',
      description: 'Reverse Osmosis (RO) + Ultra Filtration (UF)+ Minerals (Ca, Mg, Copper)',
      //price: 10.99,
      imageUrl: product1Image,
      width: 600,
      height: 600
    },
    {
      id: 2,
      //name: 'Product 2',
      description: 'Only Reverse Osmosis (RO)',
      //price: 19.99,
      imageUrl: product2Image,
      width: 600,
      height: 600
    },
    {
      id: 3,
      //name: 'Product 3',
      description: 'Green Purifier – Ultraviolet (UV) + Ultra Filtration (UF) + Minerals (Ca, Mg, Copper)',
      //price: 14.99,
      imageUrl: product3Image,
      width: 600,
      height: 600
    },
    {
      id: 4,
      //name: 'Product 4',
      description: 'Reverse Osmosis (RO) + Ultraviolet (UV)+ Ultra Filtration (UF)+ Minerals (Ca, Mg, Copper)',
      //price: 24.99,
      imageUrl: product4Image,
      width: 600,
      height: 600
    }
  ];

  export default Products;