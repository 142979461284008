import React from 'react';
import Products from './Products';

const ProductsPage = () => {
  return (
    <div>
      <h1 className='bg-slate-300 px-auto text-center font-bold text-xl p-2 m-2'>Products</h1>
      <div className="product-container">
        {Products.map(Products => (
          <div key={Products.id} className="product">
            <img src={Products.imageUrl} alt={Products.name} width={Products.width} height={Products.height} />
            <p>{Products.description}</p>
            {/* <h2>{Products.name}</h2>
            <p>Price: ${Products.price}</p> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsPage;
